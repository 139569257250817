<template>
  <div class="intRule">
    <div style="height: 30px">
      <div class="title">
        <div class="span"></div>
        设置积分规则
      </div>
      <div class="ruleBtn">
        <el-button type="primary" @click="createRule()" :loading="loading"
          >保存</el-button
        >
      </div>
    </div>

    <div class="cutLine"></div>
    <div class="rule">
      <el-radio-group v-model="share_type">
        <el-radio :label="1">所有门店公用一种积分规则</el-radio>
        <el-radio :label="2">每个门店各自设置积分规则 </el-radio>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
import { getFind, createRule, updateRule } from "@/api/system/integralRule";
export default {
  data() {
    return {
      share_type: 0,
      loading: false,
      client_is_share_integral_id: null,
    };
  },
  created() {
    this.getFind();
  },
  methods: {
    /*
     *  获取积分规则
     */
    getFind() {
      getFind().then((res) => {
        if (res.code === 1) {
          if (res.data !== null) {
            this.share_type = res.data.share_type;
            this.client_is_share_integral_id =
              res.data.client_is_share_integral_id;
          }
        }
      });
    },
    /*
     *  设置积分规则
     */
    createRule() {
      if (this.client_is_share_integral_id === null) {
        createRule({ share_type: this.share_type }).then((res) => {
          if (res.code === 1) {
            this.$message({
              message: "设置成功！",
              type: "success",
            });
            this.getFind();
          }
        });
      } else {
        updateRule({
          share_type: this.share_type,
          client_is_share_integral_id: this.client_is_share_integral_id,
        }).then((res) => {
          if (res.code === 1) {
            this.$message({
              message: "设置成功！",
              type: "success",
            });
            this.getFind();
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.intRule {
  height: 100vh;
  padding-top: 20px;
  background-color: white;
  .cutLine {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 1px;
    background-color: #ddd;
  }

  .rule {
    margin-left: 20px;
  }
  .title {
    float: left;

    display: flex;
    margin-left: 20px;
    font-size: 16px;

    .span {
      margin: auto 10px auto 0;
      width: 4px;
      height: 16px;
      background: #5493f5;
      border-radius: 2px;
    }
  }
  .ruleBtn {
    float: right;
    margin-right: 20px;
  }
}
</style>